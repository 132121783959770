import React, { useState } from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'
import { Button } from 'src/components/button'

import './style.css'
import LoadingIndicator from 'src/components/LoadingIndicator'
import CustomCheckbox from 'src/components/CustomCheckbox'
import { useTranslation } from 'react-i18next'

const PROS = [
  'Localized platform with 10+ languages',
  'AI-powered engine for automated & personalized experience',
  '24/7 customer support',
  'Unlimited users on platform',
  'Auto-synchronized with your security policies',
  'Easy user management by department, groups and roles',
]

export const PricingPage = () => {
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [emailError, setEmailError] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [agreeError, setAgreeError] = useState(null)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [employeesNumber, setEmployeesNumber] = useState('1-50')

  const { t } = useTranslation()

  const clearForm = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setCompanyName('')
    setEmployeesNumber('')
    setAgree(false)
    setAgreeError(null)
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const handleSendForm = () => {
    if (!validateEmail(email)) {
      setEmailError(true)
    }
    if (!companyName) {
      setCompanyError(true)
    }
    if (!agree) {
      setAgreeError(true)
    }
    if (validateEmail(email) && !!companyName && agree) {
      setLoading(true)
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        employeesNumber,
      }
      fetch(window._env_.REACT_APP_API_HOST + '/v1/companies/demo-request', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => {
        // console.log(response.status);
        if (response.status) {
          setLoading(false)
          clearForm()
          setSuccessMessage('Success! Data has been sent.')
        } else {
          setLoading(false)
          setErrorMessage('Something went wrong, please try again')
        }
      })
    }

  }

  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='darker heading-section'>
        <div className='container'>
          <div className='row mob-column'>
            <div className='column one-of-2 mob-fullwidth pricing-text-box'>
              <p className='sato34 font500 mob-sato-26-700 mb36 mw400 mob-mw-243' >{t("It’s time to rethink how to")}<br className='mob-hide' /> {t("mitigate your biggest")}<br className='mob-hide' /> {t("security risks.")}<br className='mob-hide' />
                {t("Predict and prevent email")}<br className='mob-hide' /> {t("based attacks with AI")}<br className='mob-hide' />{t("- powered solutions.")}</p>

              <p className='mb87 mob-mb-40 font18 mob-font-16' style={{ maxWidth: 410 }}>{t("Let’s set up a meeting to choose a pricing plan tailored to your business needs.")}</p>

              <div className='column pricing-pros'>
                {PROS.map(el => (
                  <div key={el.replaceAll(' ', '')} className='row pricing-pros-item'>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="13" cy="13" r="13" fill="#6D4AFF" fillOpacity="0.1" />
                      <path d="M8.26562 14.1814L11.6437 16.8076L17.7202 9.4541" stroke="#6D4AFF" strokeWidth="1.77273" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className='mob-font14' style={{ maxWidth: 310 }}>{t(el)}</p>
                  </div>
                ))}


              </div>
            </div>


            <div style={{ opacity: loading ? 0.5 : 1, position: 'relative' }} className='br30 padding80 mob-padding-80-32 dark-box box-shadow one-of-2 mob-fullwidth column'>

              {loading && <div style={{ position: 'absolute', background: '#ffffff40', width: 'calc( 100% - 160px)', height: 'calc( 100% - 160px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box' }}>
                <LoadingIndicator />
              </div>}
              {successMessage && <p style={{
                color: '#00BC62',
                marginBottom: 20,
                marginTop: -20,
                fontWeight: 600
              }}>{successMessage}</p>}

              {errorMessage && <p style={{
                color: '#B40020',
                marginBottom: 20,
                marginTop: -20,
                fontWeight: 600
              }}>{errorMessage}</p>}

              <label htmlFor="firstName" >{t("First name")}</label>
              <input value={firstName} onChange={e => setFirstName(e.target.value)} style={{ padding: '0px 20px' }} id="firstName" type="text" />

              <label htmlFor="lastName" >{t("Last name")}</label>
              <input value={lastName} onChange={e => setLastName(e.target.value)} style={{ padding: '0px 20px' }} id="lastName" type="text" />

              <label htmlFor="email" >{t("Work email")}<span style={{ color: '#FA145F' }}>*</span></label>
              <input value={email} onChange={e => {
                setEmail(e.target.value)
                setEmailError(null)
              }} style={{ padding: '0px 20px' }} id="email" type="text" />
              {emailError && <p style={{ color: '#FA145F', margin: '-15px 0px 20px 0px' }}>{t("Please check email")}</p>}

              <label htmlFor="phoneNumber" >{t("Phone number")}</label>
              <input
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e.target.value)
                }}
                style={{ padding: '0px 20px' }}
                id="phoneNumber"
                type="text"
              />

              <label htmlFor="companyName" >{t("Company name")}<span style={{ color: '#FA145F' }}>*</span></label>
              <input value={companyName} onChange={e => {
                setCompanyName(e.target.value)
                setCompanyError(null)
              }} style={{ padding: '0px 20px' }} id="companyName" type="text" />
              {companyError && <p style={{ color: '#FA145F', margin: '-15px 0px 20px 0px' }}>{t("Company name is required")}</p>}
              <label htmlFor="employeesAmount" >{t("Number of employees")}</label>
              {/* <input value={employeesNumber} onChange={e => setEmployeesNumber(e.target.value)} style={{ padding: '0px 20px' }} id="employeesAmount" type="text" /> */}
              <select value={employeesNumber} onChange={e => setEmployeesNumber(e.target.value)} style={{ padding: '0px 20px' }} id="employeesAmount" name="employeesNumber" >
                <option value="1-50">1-50</option>
                <option value="51-100">51-100</option>
                <option value="101-250">101-250</option>
                <option value="251-1000">251-1000</option>
                <option value="1000+">1000+</option>
              </select>

              {/* <p style={{ color: '#9999FE', lineHeight: '19px', fontSize: '12px', marginBottom: '40px' }}>
                <strong>Are you looking to become a partner/reseller?</strong> Please fill in the partner request form on our <a style={{ color: '#0157FF' }} target='_blank' href="/">partner page</a>.
                Cyberlift needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at anytime. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, check out our <a style={{ color: '#0157FF' }} target='_blank' href="/">Privacy Policy</a>.
              </p> */}
              <div className='row ' style={{ marginBottom: 20 }}>
                <CustomCheckbox checked={agree} onChange={() => {
                  setAgree(!agree)
                  setAgreeError(null)
                }} />

                <label htmlFor="consent" style={{
                  margin: '0px 0px 0px 10px', lineHeight: 1.5, ...(agreeError && {
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'wavy',
                    textDecorationColor: '#FA145F'
                  }
                  )
                }} ><p>{t("By clicking the button you agree")} <br /> {t("with our")} <a target='_blank' className='form-link' href="/privacy-policy">{t("Privacy Policy")}</a><span style={{ color: '#FA145F' }}>*</span></p></label>

              </div>

              <div className='column column-center'>

              </div>
              <div className='text-center'>
                <Button onClick={handleSendForm} style={{ padding: '16px 32px' }}>{t("Request quote")}
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
                  </svg>
                </Button>
              </div>


            </div>
          </div>
        </div>
      </section >
      <section></section>
      <section></section>
      <section></section>
      <Footer theme={THEMES.dark} />
    </>
  )
}
